<template>
  <div class="inner-tabbar">
    <div class="one" align="center" @click="jumpTabbar(1)">
      <img
        v-if="value == 'one'"
        class="one-activity"
        src="@/assets/home-activity-2x.png"
        alt=""
      />
      <img v-else class="one-gray" src="@/assets/home-page-2x.png" alt="" />
      <div :style="value == 'one' ? 'color:#0A68D8' : 'color:#3F454C'">
        {{ $t('enroll.Homepage') }}
      </div>
    </div>
    <div class="two" align="center" @click="jumpTabbar(2)">
      <img
        v-if="value == 'two'"
        class="second-activity"
        src="@/assets/second-activity-2x.png"
        alt=""
      />
      <img
        v-else
        class="second-gray"
        src="@/assets/second-page-2x.png"
        style="width:24px;height:24px;margin-bottom:4px;"
        alt=""
      />
      <div :style="value == 'two' ? 'color:#0A68D8' : 'color:#3F454C'">
        {{ $t('enroll.Governance_Committee') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'one',
    },
  },
  methods: {
    jumpTabbar(num) {
      if (num == 1) {
        this.$router.push('/')
      } else if (num == 2) {
        this.$router.push('/governance')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.inner-tabbar {
  width: 100%;
  height: 73px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0px;
  background-color: #fff;
  display: flex;
  box-sizing: border-box;
  padding-top: 15px;
  .one,
  .two {
    width: 100%;
    font-size: 12px;
  }
  .one-gray,
  .one-activity {
    width: 22px;
    height: 22px;
    margin-bottom: 6px;
  }
  .second-gray,
  .second-activity {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
}
</style>
