<template>
  <div>
    <div class="top">
      <div class="one">
        <img src="@/assets/yip-logo-2x.png" alt="" />
        <Language class="lang" @fetchData="onLoad" />
      </div>
      <div class="two" style="position:relative;">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item
            v-model="value1"
            :options="[
              { text: $t('second.All_Types'), value: '0' },
              { text: $t('second.Token_Holders_Vote'), value: '1' },
              { text: $t('second.Governance_Committee_Vote'), value: '2' },
            ]"
            :title="dropTitle"
            @change="isChangeDrop"
          />
        </van-dropdown-menu>
        <div class="btn" @click="jumpInitiate" v-show="showInitiateBtn">
          {{ $t('second.Initiate_Proposal') }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="list" v-if="!noDate">
        <!-- {{ $t('index.Proposal_list') }} -->
      </div>
      <div v-if="noDate" class="nodate-box">
        <img src="@/assets/nodate.png" alt="" />
        <p>{{ $t('index.temporarily_no_data') }}</p>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('index.no_more')"
        @load="onLoad"
        :offset="10"
        v-if="!noDate"
        :loading-text="$t('tip.loading')"
      >
        <van-cell
          v-for="(item, index) in listArr"
          :key="index"
          @click="jumpDetail(item.id, item.type)"
        >
          <div class="listwrap">
            <div class="title">{{ item.title }}</div>
            <div class="detail">{{ item.background }}</div>
            <div class="time">
              <div class="timeleft">{{ $t('index.start_time') }}</div>
              <div class="timeright">
                {{ timeKind(item.start_time, 1) }}
                {{ timeKind(item.start_time, 2) }}
              </div>
            </div>
            <div class="time">
              <div class="timeleft">{{ $t('index.end_time') }}</div>
              <div class="timeright">
                {{ timeKind(item.end_time, 1) }}
                {{ timeKind(item.end_time, 2) }}
              </div>
            </div>
            <div class="listbtmwrap">
              <div class="listbtm">
                <van-icon name="underway-o" color="#7A7B8B" size="16" />
                <div>
                  {{
                    voteKind(
                      new Date().getTime(),
                      item.start_time,
                      item.end_time,
                      item
                    )
                  }}
                </div>
              </div>
              <div v-if="item.status == 1" style="color: #4fae42">
                {{ $t('index.proposal_approved') }}
              </div>
              <div v-if="item.status == -1" style="color: #e52323">
                {{ $t('index.proposal_not_approved') }}
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
    <div style="height: 80px"></div>
    <tabbar value="one"></tabbar>
  </div>
</template>

<script>
import Language from '@/components/language'
import { getProposalList } from '@/api/index'
import { timeType } from '@/utils/index'
import tabbar from '@/components/tabbar'
export default {
  components: {
    Language,
    tabbar,
  },
  data() {
    return {
      listArr: [],
      loading: false,
      finished: false,
      getObj: {
        limit: 20,
        start: 0,
      },
      noDate: false,
      dropTitle: '',
      optionDrop: [],
      value1: '',
    }
  },

  computed: {
    showInitiateBtn() {
      return this.account && this.user && this.user['is_committee'] == 1
    },
  },

  methods: {
    jumpInitiate() {
      this.$router.push('./initiate')
    },
    // 改变提案类型
    isChangeDrop() {
      if (this.value1 == 0) {
        this.dropTitle = this.$t('second.All_Types')
        console.log(this.dropTitle)
      } else if (this.value1 == 1) {
        this.dropTitle = this.$t('second.Token_Holders_Vote')
      } else if (this.value1 == 2) {
        this.dropTitle = this.$t('second.Governance_Committee_Vote')
      }
      this.noDate = false
      this.finished = true
      this.listArr = []
      this.getObj.start = 0
      setTimeout(() => {
        this.finished = false
      })
      return
    },
    // 验签获取数据程序
    // async getSign() {

    // },
    // 获取数据
    async fetchData() {
      this.getObj.language = this.$store.state.lang
      this.getObj.type = this.value1
      // 输入英文的时候

      let res = await getProposalList(this.getObj)
      if (res.total == 0) {
        this.noDate = true
      } else {
        this.noDate = false
      }
      this.loading = false
      this.listArr.push(...res.data)
      console.log(this.listArr.length)
      if (
        this.listArr.length == res.total ||
        res.data.length < this.getObj.limit
      ) {
        this.finished = true
        return
      }
    },
    timeKind(time, num) {
      return timeType(time, num)
    },
    voteKind(time, start, end, item) {
      if (item.status == 2) {
        return this.$t('second.Supporting') + '...'
      }
      time = time.toString().substr(0, 10)
      time = time.substr(0, 10)
      if (Number(start) > Number(time)) {
        return this.$t('index.voting_did_not_start')
      } else if (Number(time) > Number(end)) {
        let over = Number(time) - Number(end)
        over = over + '000'
        if (Math.ceil(over / (1000 * 3600 * 24)) == 1) {
          let nowDay = new Date(Number(time + '000')).getDate()
          let endDay = new Date(Number(end + '000')).getDate()
          if (nowDay == endDay) {
            return this.$t('index.vote_over_today')
          } else {
            return this.$t('index.voting_ended') + 1 + this.$t('index.day_ago')
          }
        } else {
          return (
            this.$t('index.voting_ended') +
            Math.ceil(over / (1000 * 3600 * 24)) +
            this.$t('index.day_ago')
          )
        }
      } else if (Number(start) < Number(time) && Number(time) < Number(end)) {
        return this.$t('index.voting_underway')
      }
    },
    // 加载的数据
    onLoad(msg) {
      // console.log(this.dropTitle)
      if (this.value1 === '0') {
        this.dropTitle = this.$t('second.All_Types')
      } else if (this.value1 == 1) {
        this.dropTitle = this.$t('second.Token_Holders_Vote')
      } else if (this.value1 == 2) {
        this.dropTitle = this.$t('second.Governance_Committee_Vote')
      } else {
        this.dropTitle = this.$t('second.Please_select_proposal_type')
      }
      if (msg == 'changeLa') {
        this.finished = true
        this.listArr = []
        this.getObj.start = 0
        setTimeout(() => {
          this.finished = false
        })
        return
      }
      this.fetchData()
      setTimeout(() => {
        this.getObj.start += 20
      })
    },
    // 跳转到详情页面
    jumpDetail(ids, type) {
      if (type == 2) {
        this.$router.push({ path: 'governVote', query: { id: ids } })
      } else {
        this.$router.push({ path: 'detail', query: { id: ids } })
      }
    },
  },
  created() {
    console.log(this.$store.state)
    this.dropTitle = this.$t('second.Please_select_proposal_type')
    this.optionDrop = [
      { text: this.$t('second.All_Types'), value: '0' },
      { text: this.$t('second.Token_Holders_Vote'), value: '1' },
      { text: this.$t('second.Governance_Committee_Vote'), value: '2' },
    ]
  },
}
</script>
<style lang="less" scoped>
/deep/ .van-cell {
  padding: 0px;
  background-color: (rgba(0, 0, 0, 0));
  line-height: normal;
}
/deep/ .van-dropdown-menu__bar {
  box-shadow: none;
  height: auto;
  .van-dropdown-menu__item {
    height: 35px;
    .van-ellipsis {
      font-size: 14px;
      color: #777789;
    }
  }
  /deep/ .van-dropdown-menu__title {
    &::after {
      border-color: transparent transparent #181a33 #181a33;
    }
  }
}
/deep/ .van-cell__title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
}
/deep/ .van-popup {
  // box-shadow: -2px -3px 5px red;
  box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.1);
}
/deep/ .van-dropdown-item {
  top: 54px !important;
  position: absolute !important;
  width: 100vw;
  height: 100vh;
  left: -13px;
  .van-icon {
    padding-right: 17px;
  }
}
/deep/ .van-cell__value {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
</style>
<style lang="less" scoped>
.nodate-box {
  position: relative;
  overflow: hidden;
  margin-top: 230px;
  img {
    width: 54px;
    height: 62px;
    margin: 0 auto;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  p {
    text-align: center;
    font-size: 14px;
    color: #181a33;
    line-height: 21px;
    margin-top: 80px;
  }
}
.top {
  background-color: #ffffff;
  padding-left: 13px;
  padding-right: 13px;
  box-sizing: border-box;
  padding-top: 22px;
  padding-bottom: 3px;
  border-radius: 6px;
  .one {
    display: flex;
    align-items: center;
    padding-left: 9px;
    box-sizing: border-box;
    justify-content: space-between;
    padding-bottom: 11px;
    border-bottom: 1px solid #f0f0f0;
    img {
      width: 93px;
      height: 45px;
    }
  }
  .two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5px;
    .btn {
      min-width: 96px;
      line-height: 35px;
      height: 35px;
      background: #0073ee;
      border-radius: 4px;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      word-wrap: break-word;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.bottom {
  padding: 0 13px;
  .list {
    font-size: 14px;
    font-weight: 400;
    color: #181a33;
    margin-bottom: 11px;
  }
  .listwrap {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 22px 19px;
    box-sizing: border-box;
    margin-bottom: 15px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #181a33;
      margin-bottom: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .detail {
      font-size: 14px;
      font-weight: 400;
      color: #777789;
      padding-bottom: 22px;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #777789;
      margin-bottom: 13px;
      .timeright {
        color: #181a33;
      }
    }
    .listbtmwrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      .listbtm {
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 12px;
        color: #777789;
        .van-icon {
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
