<template>
  <div>
    <div class="lang-wrapper">
      <div @click="show = true">
        {{ this.language == 'zh' ? '简体中文' : 'English' }}
        <van-icon name="arrow-down" size="12" />
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        :confirm-button-text="$t('index.confirm')"
        :cancel-button-text="$t('index.cancel')"
        :default-index="dindex"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { loadLanguageAsync } from '@/plugins/i18n'
export default {
  components: {},
  data() {
    return {
      language: '',
      show: false,
      columns: ['简体中文', 'English'],
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    dindex() {
      const idx = this.language == 'zh' ? 0 : 1
      return idx
    },
  },
  mounted() {
    console.log('lang===>', this.lang)
    this.language = this.lang
  },
  methods: {
    onConfirm(value, index) {
      this.language = index == 0 ? 'zh' : 'en'
      this.show = false
      console.log(this.language, '语言')
      this.$store.dispatch('setLanguage', this.language)
      loadLanguageAsync(this.language)
      this.$emit('fetchData','changeLa');
    },
  },
}
</script>

<style lang="less" scoped>
.lang-wrapper {
  z-index: 2000;
  font-size: 14px;
}
</style>
